<template>
  <v-dialog
    v-model="show"
    class="pa-0"
    :width="waiting ? '450px' : '722px'"
  >
    <v-card
      v-if="!waiting"
      id="container"
    >
      <img
        id="base"
        :src="require('@/assets/images/Overlijdensberichten livestream background.jpg')"
      />
      <img
        class="livestreamFoto"
        id="photo"
        :src="Photo"
      />
      <div
        id="moment"
      >
        <p>
          Afscheidsplechtigheid op {{ new Date(Moment).toLocaleDateString('nl-BE', {day: "numeric", month: "long", weekday: 'long'}) }} om {{ FormatMixin_FormatDateTime(Moment, formatMixin.date.database, formatMixin.date.time) }} uur
        </p>
      </div>
      <div
        id="name"
      >
        <p v-html="Name" />
      </div>
      <div
        class="pa-5"
        id="info"
      >
        <p>
          Geef de code in om de uitvaart te bekijken.
        </p>
        <v-text-field
          v-model="code"
          base-color="primary"
          bg-color="white"
          color="primary"
          density="compact"
          :disabled="stopped"
          label="De code"
          variant="outlined"
          :readonly="stopped"
        />
        <v-btn
          color="secondary"
          :disabled="stopped"
          tile
          @click="OpenLivestream()"
        >
          {{ stopped ? 'De livestream is afgelopen' : 'Naar de livestream'}}
        </v-btn>
        <p
          v-if="!stopped && url"
        >
          Klik <a :href="url" target="_blank">hier</a> als uw browser de livestream niet automatisch opent.
        </p>
      </div>
    </v-card>
    <v-card
      v-else
      class="pa-5"
    >
      <div
        class="text-center"
      >
        <h2
          class="colorSecondary font-weight-bold py-3 text-center"
        >
          Livestream uitvaart <span v-html="Name" />
        </h2>
        <div
          class="text-center"
        >
          <p>
            De uitvaart is nog niet begonnen.
            <br>
            We openen de livestream op {{ new Date(Start).toLocaleDateString('nl-BE', {day: "numeric", month: "long"}) }} om {{ FormatMixin_FormatDateTime(Start, formatMixin.date.database, formatMixin.date.time) }} uur.
          </p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'
import formatMixin from '@/mixins/formatMixin'
import klantService from '@/services/klantService'

export default {
  name: 'OverlijdensberichtenLivestream',
  mixins: [
    commonMixin,
    formatMixin
  ],
  props: {
    modelValue: Object
  },
  data () {
    return {
      code: null,
      interval: null,
      show: true,
      started: false,
      stopped: false,
      url: null,
      waiting: false
    }
  },
  beforeUnmount () {
    this.IntervalClear()
  },
  mounted () {
    this.CheckIfStopped()
    this.CheckIfStarted()
  },
  watch: {
    show (modelValue) {
      if (!modelValue) {
        this.$emit('update:modelValue', null)
      }
    },
    started (modelValue) {
      this.IntervalClear()

      if (modelValue) {
        this.interval = setInterval(() => this.CheckIfStopped(), new Date(this.Stop) - new Date())

        if (this.waiting) {
          this.OpenLivestream()
        }
      }
    }
  },
  computed: {
    IdDossier () {
      return this.modelValue?.idDossier
    },
    Moment () {
      return this.modelValue?.deadline.uitvaartdienstDatum
    },
    Name () {
      return this.modelValue?.naamOverschrijven ? this.modelValue.naam : this.modelValue.naamStandaard
    },
    Photo () {
      return this.modelValue?.foto.blobDataString
    },
    Start () {
      return this.modelValue?.deadline.livestreamStart
    },
    Stop () {
      return this.modelValue?.deadline.livestreamStop
    }
  },
  methods: {
    CheckIfStarted () {
      this.started = new Date(this.Start) < new Date()

      if (!this.started) {
        this.interval = setInterval(() => this.CheckIfStarted(), new Date(this.Start) - new Date())
      }
    },
    CheckIfStopped () {
      this.stopped = new Date(this.Stop) <= new Date()
    },
    IntervalClear () {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    async OpenLivestream () {
      if (this.code) {
        this.CommonMixin_Load(this.$options.name, true)
        const resp = await klantService.GetOverlijdensberichtDetailLivestream(this.IdDossier, this.code)

        if (resp && resp.data !== null) {
          this.url = resp.data
          if (resp.data === '') {
            this.waiting = true
          } else {
            window.open(resp.data, '_blank')
          }
        }

        this.CommonMixin_Load(this.$options.name, false)
      }
    }
  }
}
</script>
<style>
  #base {
    max-width: 722px;
  }

  #container {
    overflow: hidden;
    position: relative;
  }

  #info {
    background-color: white;
    margin-top: -7px;
    text-align: center;
  }

  #moment {
    height: 90px;
    left: 5px;
    position: absolute;
    text-align: center;
    top: 240px;
    width: 226px;
  }

  #moment p {
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  #name {
    height: 85px;
    left: 385px;
    position: absolute;
    text-align: center;
    top: 312px;
    width: 337px;
  }

  #name p {
    color: white;
    font-family: 'Ink Free';
    font-size: 30px;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  #photo {
    left: 441px;
    position: absolute;
    top: 43px;
    width: 225px;
    border: 5px soli
  }
</style>
